<template>
  <div>
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div class="row">
      <b-tabs v-model="tabIndex" class="content-d-none w-100">
        <b-tab title="View Listings"></b-tab>
        <b-tab title="Driver Listings"></b-tab>
      </b-tabs>
    </div>
    <div class="row">
      <b-tabs class="nav-d-none w-100">
        <b-tab>
          <div>
          <transition name="slide-fade" mode='out-in'>
            <VueBootstrap4Table 
              :classes="classes"
              :key="tabIndex"
              :rows="list" 
              :columns="tabIndex === 0 ? columns : columns1" 
              :config="config"
              :total-rows="listTotal"
              @on-change-query="onChangeQuery"
            >
              <template slot="empty-results">
                <div
                  v-if="!isLoading"
                  class="bold-text"
                >No records found</div>
                <div
                  v-if="isLoading"
                  class="row justify-content-center mt-3"
                ><div class="loading"></div></div>
              </template>
              <template slot="action" slot-scope="props">
                <div class="col-8 mx-auto">
                  <router-link :to="'/user-management/detail/' + props.row.userId" class="link-underscore-red justify-content-center">Detail View </router-link>
                </div>
              </template>
              <template slot="custom-status" slot-scope="props">
                <div :class="{'green-text': props.row.status === 'Active'}">
                  {{ props.row.status }}
                </div>
              </template>
              <template slot="paginataion-previous-button"><i class="arrow my-auto left blur"></i></template>
              <template slot="paginataion-next-button"><i class="arrow my-auto right blur"></i></template>
            </VueBootstrap4Table>
          </transition>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div v-if="canAdd" class="page-footer" style="bottom: 110px;">
      <div class="row justify-content-center button-add">
        <b-button @click="$router.push('/user-management/detail/add')" class="px-5">Add</b-button>
      </div>
    </div>
    <div style="padding-bottom: 18rem"></div>
  </div>
  </div>
</template>
<script>

import * as userActions from "../../store/modules/user/actions"
import clone from "lodash/clone"

export default {
  name: "UserManagement",
  props: ['tab'],
  components: {
    VueBootstrap4Table: () => import("@/components/ui/VueBootstrap4Table/VueBootstrap4Table"),
  },
  watch: {
    tabIndex: {
      handler(newValue){
        this.onTabsChanged(newValue);
        this.canAdd = newValue === 0 ? this.currentUser.isAdmin : false
      }
    },
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    },
  },
  data(){
    return{
      isLoading: false,
      tabIndex: this.tab || 0,
      canAdd: false,
      currentUser: this.$store.getters.getUser,
      list: [],
      listTotal: 0,
      columns: [{
        label: "id",
        visibility: false,
        name: "userId",
        sort: true,
      },
      {
        label: "User Name",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "User Role",
        name: "role",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
        width: "20%"
      },
      {
        label: "Status",
        name: "status",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "10%",
        slot_name: "custom-status",
      },
      {
        label: "Actions",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      columns1: [{
        label: "id",
        visibility: false,
        name: "userId",
        sort: true,
      },
      {
        label: "User Name",
        name: "name",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "IC/Passport",
        name: "loginId",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Status",
        name: "status",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "10%",
        slot_name: "custom-status",
      },
      {
        label: "Actions",
        name: "action",
        column_text_alignment: "text-center",
        row_text_alignment: "text-center",
        width: "25%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: false,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          showSearchButton: false,
          searchOnPressEnter: false,
          placeholder: "Search User",
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      classes: {
        table: {
          "spci-table": true,
        }
      }
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    onTabsChanged(index) {
      if (index === 0) {
        document.getElementById('header-route-name').textContent = 'View Listings';
      } else if (index === 1) {
        document.getElementById('header-route-name').textContent = 'Driver Listings';
      }
      this.getList('all');
    },
    getList() {
      this.isLoading = true;
      // const page = this.queryParams.page;
      // const limit = this.queryParams.per_page;
      // const search = this.queryParams.global_search;
      // const filter = {page: page, limit: limit, status: status, search: search, orderCol: orderCol, desc: desc};
      this.$store.dispatch(userActions.USER_PROFILE_LIST_REQUEST, {}).then(res => {
        if (res.status === "ok" && res.response) {
          this.list = this.$store.getters.getUserListing(this.tabIndex === 1)
          this.parseList(this.list);
          this.listTotal = this.list.length;
        }
      }).finally(() => {this.isLoading = false;})
    },
    parseList(list) {
      list.forEach(el => {
        el.role = this.$store.getters.parseUserRole(el.role)
        el.status = this.$store.getters.parseUserStatus(el.status)
      })
    },
  },
  mounted() {
    this.tabIndex = clone(this.$route.params.index ? +this.$route.params.index : 0)
    this.onTabsChanged(this.tabIndex);
    this.canAdd = this.tabIndex === 0 ? this.currentUser.isAdmin : false
  }
}
</script>
<style lang="scss" scoped>
.green-text {
  color: #22D60E;
}
</style>